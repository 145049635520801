import styled from 'styled-components';

const Container = styled.div`
  position: relative;

  .inner-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;
    whitespace: nowrap;
    padding-right: 30px;
    margin: 0 auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255));
  }
`;

export { Container };
