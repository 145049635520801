import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import RichText from 'components/RichText';
import BackgroundImage from 'components/Core/BackgroundImage';
import useBreakpointView from 'utils/useBreakpointView';
import richTextOptions from './richTextOptions';
import iconLinkArrow from '../images/link-arrow.svg';
import Container from './styles';

const InPageFeaturesV7 = ({ title, description, inPageNavigationItems }) => {
  const [selectedNavigation, setSelectedNavigation] = useState(0);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const isMobile = useBreakpointView(['xs', 'sm']);
  const isTablet = useBreakpointView(['xs', 'sm', 'md']);

  useEffect(() => {
    setSelectedNavigation(0);
  }, [inPageNavigationItems]);

  return (
    <Container>
      <div className="p-[60px] rounded-[20px] border-[1px] border-solid border-grey-3 mt-[20px] md-max:mt-[10px] md-max:px-[20px] md-max:py-[25px]">
        <div>
          <h2 className="!text-left mb-[20px] md-max:mb-[10px]">{title}</h2>
          <p className="font-heading text-[20px] md-max:text-[17px] !text-left text-darkest">
            {description?.description}
          </p>
        </div>

        <div className="in-page-features--navigation-content w-full mt-[40px] md:mt-[25px]">
          <Slider
            asNavFor={nav2}
            ref={(slider) => setNav1(slider)}
            variableWidth
            focusOnSelect
            arrows={false}
            infinite={isTablet}
            centerMode={isTablet}
            autoplay={isMobile}
            autoplaySpeed={5000}
            speed={isMobile ? 500 : 0}
            afterChange={(index) => setSelectedNavigation(index)}
          >
            {inPageNavigationItems?.map((item, index) => (
              <React.Fragment key={`in-page-nav-${index}`}>
                <div
                  className={`relative mr-[5px] font-heading text-desktop-h5 font-normal text-grey-6 border-0 border-b-2 border-solid border-transparent py-[10px] ${
                    selectedNavigation === index
                      ? '!text-titan-blue-3 !font-bold !border-titan-blue-3'
                      : 'hover:font-bold'
                  } whitespace-nowrap cursor-pointer`}
                  onClick={() => setSelectedNavigation(index)}
                >
                  <span className="font-bold invisible">{item.title}</span>
                  <span className="absolute left-0">{item.title}</span>
                </div>
              </React.Fragment>
            ))}
          </Slider>
        </div>

        <div className="in-page-features--main-content">
          <Slider
            asNavFor={nav1}
            ref={(slider) => setNav2(slider)}
            swipeToSlide
            focusOnSelect
            arrows={false}
            autoplay={isMobile}
            autoplaySpeed={5000}
            slidesToShow={1}
            infinite={isTablet}
            speed={isMobile ? 500 : 0}
          >
            {inPageNavigationItems?.map((item, index) => (
              <React.Fragment key={`in-page-nav-main-${index}`}>
                <div className="flex md-max:flex-col-reverse items-center md-max:items-start justify-center gap-[60px] md-max:gap-[30px] mt-[60px] md-max:mt-[40px]">
                  <div className="flex-1">
                    <h4 className="!mt-0 !mb-[20px] md-max:!mb-[10px] !text-left">{item.title}</h4>
                    <RichText content={item.description} options={richTextOptions} />
                    {item.link && (
                      <a
                        className="flex items-baseline mt-[40px] md-max:mt-[20px] w-fit font-heading text-titan-blue-3 font-bold"
                        href={item.link}
                      >
                        Learn More
                        <img className="ml-[10px]" src={iconLinkArrow} alt="link" />
                      </a>
                    )}
                  </div>
                  <div className="flex-1 relative min-h-[370px] md-max:min-h-[200px] w-full">
                    <BackgroundImage image={item.featuredImage} objectFit="contain" />
                  </div>
                </div>
              </React.Fragment>
            ))}
          </Slider>
        </div>
      </div>
    </Container>
  );
};

export default InPageFeaturesV7;
