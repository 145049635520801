import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import DraggableScrollContainer from 'components/DraggableScrollContainer';
import InPageFeaturesV7 from 'components/Contentful/ContentfulInPageFeatures/InPageFeaturesV7';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import useBreakpointView from 'utils/useBreakpointView';

const Chisel = ({ entries = [] }) => {
  const [selectedFeature, setSelectedFeature] = useState(0);
  const inPageFeatureList = getEntry('inPageFeatureList', entries);
  const inPageFeatures = inPageFeatureList?.components;
  const styles = entries.filter((entry) => entry.templateId === 'section')?.[0]?.styles;
  const customCss = styles?.customStyling?.customStyling;
  const isMobile = useBreakpointView(['xs', 'sm']);

  const onChangeFeature = (index) => {
    setSelectedFeature(index);
  };

  const renderNavigationItems = () => {
    return inPageFeatures?.map((entry, index) => (
      <div
        className={`relative px-[40px] md-max:px-[18px] py-[15px] md-max:py-[10px] rounded-[4px] ${
          selectedFeature === index
            ? 'bg-titan-blue-3 !font-bold text-white'
            : 'text-grey-6 hover:font-bold'
        } font-heading text-center text-desktop-h5 font-normal leading-tight tracking-[-0.36px] whitespace-nowrap !overflow-visible cursor-pointer`}
        onClick={() => onChangeFeature(index)}
        key={index}
      >
        <span className="font-bold invisible">{entry.title}</span>
        <span className="absolute left-[40px] md-max:left-[18px]">{entry.title}</span>
      </div>
    ));
  };

  return (
    <Container
      className="bg-white px-[8px] md-max:px-0 py-[40px] md-max:py-[10px] w-full max-w-[1400px] mx-auto"
      customCss={customCss}
    >
      <div className="w-full gap-[20px] py-[20px] after:absolute">
        <DraggableScrollContainer className="flex align-center justify-start w-fit max-w-full gap-[20px] md:gap-[10px] min-lg">
          {renderNavigationItems()}
        </DraggableScrollContainer>
        <div className="max-lg">
          <Slider
            variableWidth
            focusOnSelect
            arrows={false}
            infinite={isMobile}
            centerMode={isMobile}
            afterChange={(index) => onChangeFeature(index)}
          >
            {renderNavigationItems()}
          </Slider>
        </div>
      </div>
      {inPageFeatures?.map((entry, index) => (
        <div className={`${index === selectedFeature ? 'block' : 'hidden'}`}>
          <InPageFeaturesV7 {...entry} key={Date.now() + index} />
        </div>
      ))}
    </Container>
  );
};

export default Chisel;

const Container = styled.div`
  ${({ customCss }) => customCss};
`;
