import styled from 'styled-components';

const Container = styled.div`
  @media (min-width: 1024px) {
    .in-page-features--navigation-content {
      .slick-track {
        transform: none !important; /* Disable transform translate */
        width: max-content !important;
        margin: 0 !important;
        display: flex;
        flex: 1;
        width: 100%; /* Ensure the track takes full width */
        overflow: none;
      }
      .slick-list {
        display: flex;
      }
      .slick-slide {
        display: inline-block;
        width: auto; /* Set width to auto to allow items to take their natural width */
        margin-right: 30px; /* Adjust margin between items as needed */
        margin-left: 30px; /* Adjust margin between items as needed */
        padding: 0;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .in-page-features--navigation-content {
    .slick-track {
      -ms-overflow-style: none;
      scrollbar-width: thin;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .slick-slide {
    padding: 0px 22px;
  }
`;

export default Container;
