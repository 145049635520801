import React from 'react';
import optionsEmbeds from 'components/RichText/options/utils/optionsEmbeds';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import RichTextHyperLink from 'components/Core/HyperLink/RichTextHyperLink';
import RichTextEmbeddedAsset from 'components/RichText/options/components/RichTextEmbeddedAsset';
import RichTextParagraphBlock from 'components/RichText/options/components/RichTextParagraphBlock';
import RichTextQuote from 'components/RichText/options/components/RichTextQuote';
import { reduceContentValues } from 'utils/text';

const headingClass = 'in-page-features-commercial--featured-content-heading';
const paragraphClass = 'in-page-features-commercial--featured-content-description font-heading';

const richTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <span className="bold">
        <b>{text}</b>
      </span>
    ),
    [MARKS.ITALIC]: (text) => (
      <span className="bold">
        <em>{text}</em>
      </span>
    ),
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node) => (
      <RichTextHyperLink href={node.data.uri}>
        {reduceContentValues(node?.content)}
      </RichTextHyperLink>
    ),
    [BLOCKS.HEADING_1]: (node, children) => <h1 className={headingClass}>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h3 className={headingClass}>{children}</h3>,
    [BLOCKS.HEADING_3]: (node, children) => <h3 className={headingClass}>{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h3 className={headingClass}>{children}</h3>,
    [BLOCKS.HEADING_5]: (node, children) => <h3 className={headingClass}>{children}</h3>,
    [BLOCKS.HEADING_6]: (node, children) => <h6 className={headingClass}>{children}</h6>,
    [BLOCKS.QUOTE]: (node, children) => <RichTextQuote>{children}</RichTextQuote>,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => optionsEmbeds(node),
    [BLOCKS.EMBEDDED_ASSET]: (node) => <RichTextEmbeddedAsset node={node} />,
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <RichTextParagraphBlock node={node} type="p" size="medium" className={`${paragraphClass}`}>
        {children}
      </RichTextParagraphBlock>
    ),
  },
};

export default richTextOptions;
